import { AfterViewInit, Component, DestroyRef, OnInit, QueryList, Signal, ViewChildren, inject } from '@angular/core';

import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MainScoreboardItemModel, OffenseDefenseScoreboardModel, TeamScoreboardItemModel, TeamSettings, TeamsScoreboardMode } from 'models';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { MatchBoxComponent } from 'shared/match-box/match-box.component';
import { selectSettings } from 'store/players/players.selectors';
import { GetMatchModelExt } from '../core/entities';
import { MatchesService } from '../core/matches.service';
import { SignalRService } from '../core/signalr.service';
import { MatchBoxComponent as MatchBoxComponent_1 } from '../shared/match-box/match-box.component';
import { OngoingMatchBoxComponent } from '../shared/ongoing-match-box/ongoing-match-box.component';
import { EloScoreboardComponent } from '../shared/scoreboard/elo-scoreboard/elo-scoreboard.component';
import { MainScoreboardComponent } from '../shared/scoreboard/main-scoreboard/main-scoreboard.component';
import { TeamsScoreboardComponent } from '../shared/scoreboard/teams-scoreboard/teams-scoreboard.component';
import { AppState, scoreboardsActions, scoreboardsSelectors } from '../store';
import { AddNewMatchComponent } from './../shared/add-new-match/add-new-match.component';
import { IMatch as StoreMatch } from './../store/matches/matches.model';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    imports: [
        MainScoreboardComponent,
        EloScoreboardComponent,
        TeamsScoreboardComponent,
        NgIf,
        MatIcon,
        MatCheckbox,
        FormsModule,
        NgFor,
        OngoingMatchBoxComponent,
        InfiniteScrollDirective,
        MatchBoxComponent_1,
        TranslateModule,
        AsyncPipe,
        InfiniteScrollDirective,
    ],
})
export class DashboardComponent implements OnInit, AfterViewInit {
    public matches: Array<GetMatchModelExt> = [];
    public showOnlyMineMatches = false;
    public ongoingMatches$: Observable<Array<StoreMatch>>;

    public readonly settings: Signal<TeamSettings>;

    public mainScoreboard$: Observable<Array<MainScoreboardItemModel>>;
    public teamScoreboard$: Observable<Array<TeamScoreboardItemModel>>;
    public eloScoreboard$: Observable<Array<OffenseDefenseScoreboardModel>>;

    private _seasonId: number = null;
    readonly #destroyRef = inject(DestroyRef);

    public boxHeight = 300;
    private _scrolleModelMatchBox = false;

    public teamsScoreboardMode = TeamsScoreboardMode;

    @ViewChildren(MatchBoxComponent)
    public matchBoxes: QueryList<MatchBoxComponent>;

    constructor(
        private readonly dialog: MatDialog,
        private readonly signalR: SignalRService,
        private readonly store: Store<AppState>,
        private readonly matchesService: MatchesService,
        private readonly route: ActivatedRoute,
    ) {
        this.settings = store.selectSignal(selectSettings);
    }

    ngOnInit() {
        this.signalR.newMatch.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
            this.reload(true);
        });
        this.mainScoreboard$ = this.store.pipe(
            select(scoreboardsSelectors.main),
            takeUntilDestroyed(this.#destroyRef),
            tap((res) => {
                if (res) {
                    this.boxHeight = 45 * res.length + 20;
                    if (this.boxHeight < 300) {
                        this.boxHeight = 300;
                    }
                }
            }),
        );
        this.teamScoreboard$ = this.store.pipe(select(scoreboardsSelectors.teams));
        this.eloScoreboard$ = this.store.pipe(select((x) => x.scoreboards.elo));

        this.ongoingMatches$ = this.store
            .select((x) => x.matches.ongoing)
            .pipe(
                filter((x) => x !== null),
                takeUntilDestroyed(this.#destroyRef),
            );

        this.store
            .select((x) => x.season.active)
            .pipe(
                filter((x) => x !== null),
                takeUntilDestroyed(this.#destroyRef),
            )
            .subscribe((season) => {
                this._seasonId = season.id;
                this.loadMatches(10, 0);
                this.store.dispatch(scoreboardsActions.loadMain());
                switch (this.settings().teamsScoreboardMode) {
                    case TeamsScoreboardMode.EloPoints:
                        this.store.dispatch(scoreboardsActions.loadElo());
                        break;
                    default:
                        this.store.dispatch(scoreboardsActions.loadTeams());
                        break;
                }
            });
    }

    ngAfterViewInit() {
        this.matchBoxes.changes.subscribe(() => {
            setTimeout(() => {
                if (!this._scrolleModelMatchBox && this.route.snapshot.queryParams.m && this.matchBoxes.length) {
                    this._scrolleModelMatchBox = true;
                    const element = this.matchBoxes.find((x) => x.match.id === +this.route.snapshot.queryParams.m);
                    if (element) {
                        element.scrollIntoView();
                    }
                }
            });
        });
    }

    loadNext() {
        this.loadMatches(10, this.matches.length);
    }

    loadMatches(take: number, skip: number) {
        this.matchesService.load(take, skip, this._seasonId, this.showOnlyMineMatches ? 'mine' : 'all').subscribe((m) => {
            if (skip !== 0) {
                this.matches.push(...m);
            } else {
                this.matches = m;
            }
        });
    }

    private reload(reloadAll = false) {
        this.matches = [];
        if (reloadAll) {
            this.store.dispatch(scoreboardsActions.loadMain());
            if (this.settings().teamsScoreboardMode === TeamsScoreboardMode.EloPoints) {
                this.store.dispatch(scoreboardsActions.loadElo());
            } else {
                this.store.dispatch(scoreboardsActions.loadTeams());
            }
        }
        this.loadMatches(10, 0);
    }

    public onlyMineChanged() {
        this.reload(false);
    }

    addMatchModal() {
        this.dialog.open(AddNewMatchComponent, {
            position: { top: '20px' },
            disableClose: true,
            width: '95%',
            maxWidth: '800px',
            maxHeight: '100vh',
        });
    }
}
